import { colors, fonts } from '../../../theme'


export default {
  container: {
    ...fonts.UniversRoman,
    fontSize: '1rem',
    color: colors.error,
  },
}
