import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

import withMemo from '../../../decorators/withMemo'
import { mergeStyles } from '../../../utils/StyleUtils'
import MarkdownText from '../../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

function FormHelpText(props) {
  const { className, text, error, classes: classesProp, tag, ...others } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  if (!text || error) {
    return null
  }

  return (
    text && (
      <MarkdownText
        text={text}
        tag={tag}
        inline
        {...others}
        className={cx(classes.container, className)}
      />
    )
  )
}

FormHelpText.styles = styles

FormHelpText.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  text: PropTypes.string,
  error: PropTypes.bool,
  tag: PropTypes.string,
}

FormHelpText.defaultProps = {
  classes: {},
  className: null,
  text: '',
  error: false,
  tag: 'span',
}

export default withMemo()(FormHelpText)
