/* eslint-disable complexity */
/* eslint-disable max-lines */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { createUseStyles } from 'react-jss'

import { generateUniqueId } from '../../../../utils/ComponentUtils'
import FormErrorText from '../../FormErrorText'
import FormHelpText from '../../FormHelpText'
import FormLabel from '../../FormLabel'
import withMemo from '../../../../decorators/withMemo'
import { mergeStyles } from '../../../../utils/StyleUtils'

import styles from './styles'


const useStyles = createUseStyles(styles)

function Text(props) {
  const {
    className,
    inputClassName,
    type,
    id,
    name,
    value,
    label,
    help,
    placeholder,
    required,
    disabled,
    error,
    maxChar,
    inputRef,
    endOrnament,
    startOrnament,
    readOnly,
    inputProps,
    onChange,
    onBlur,
    onFocus,
    classes: classesProp,
  } = props

  const [thisId] = useState(`form_text_${generateUniqueId()}`)
  const [focused, setFocused] = useState(false)

  const handleChange = (event) => {
    if (inputProps && inputProps.onChange) {
      inputProps.onChange(event)
    }
    onChange({
      name,
      value: event.currentTarget.value,
      event,
    })
  }

  const handleFocus = (event) => {
    setFocused(true)
    if (inputProps && inputProps.onFocus) {
      inputProps.onFocus(event)
    }
    onFocus({ name, event })
  }

  const handleBlur = (event) => {
    setFocused(false)
    if (inputProps && inputProps.onBlur) {
      inputProps.onBlur(event)
    }
    onBlur({ name, event })
  }

  const classesComp = useStyles({ ...props, focused })
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <div className={cx(classes.container, className)}>
      <>
        {label !== '' && (
          <label
            className={classes.label}
            htmlFor={id || thisId}
          >
            <FormLabel
              className={classes.formLabel}
              required={required}
              error={!!error}
            >
              {label}
            </FormLabel>
            <FormHelpText
              className={classes.helpText}
              text={help}
              error={!!error}
            />
          </label>
        )}
        <div className={classes.inputContainer}>
          {startOrnament && (
            <div className={cx(classes.ornament, classes.startOrnament)}>{startOrnament}</div>
          )}
          <input
            {...inputProps}
            id={id || thisId}
            ref={inputRef}
            className={cx(classes.input, inputClassName)}
            type={type}
            name={name}
            aria-label={name}
            value={value}
            required={required}
            disabled={disabled}
            readOnly={readOnly}
            maxLength={maxChar}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            placeholder={placeholder}
          />
          {endOrnament && (
            <div className={cx(classes.ornament, classes.endOrnament)}>{endOrnament}</div>
          )}
        </div>
      </>
      <FormErrorText
        className={classes.errorText}
        text={error}
        tag="label"
        htmlFor={id || thisId}
      />
    </div>
  )
}

Text.styles = styles

Text.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  inputClassName: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['email', 'password', 'text', 'number', 'hidden']),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  help: PropTypes.string,
  placeholder: PropTypes.string,
  maxChar: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  onChange: PropTypes.func, // {name, value, event}
  onBlur: PropTypes.func, // {name, event}
  onFocus: PropTypes.func, // {name, event}
  inputRef: PropTypes.func,
  endOrnament: PropTypes.node,
  startOrnament: PropTypes.node,
}

Text.defaultProps = {
  classes: {},
  className: null,
  id: null,
  inputClassName: null,
  value: '',
  label: '',
  type: 'text',
  required: false,
  disabled: false,
  readOnly: false,
  error: null,
  help: null,
  placeholder: null,
  maxChar: null,
  inputProps: null,
  onChange: () => undefined,
  onBlur: () => undefined,
  onFocus: () => undefined,
  inputRef: () => undefined,
  endOrnament: null,
  startOrnament: null,
}

export default withMemo()(Text)
