import { useCallback } from 'react'
import cx from 'classnames'

import withMemo from '../../../../decorators/withMemo'
import { renderComponent } from '../../../../helpers/FormHelpers'


const Field = withMemo()((props) => {
  const { fieldConfig, fieldsProps, formikProps, renderer = renderComponent, className } = props
  const {
    values,
    errors,
    touched,
    handleBlur,
    isSubmitting,
    submitCount,
    setFieldValue,
  } = formikProps

  const onChange = useCallback(
    ({ name, value }) => {
      if (!fieldsProps || !fieldConfig) {
        return
      }
      if (fieldsProps && fieldsProps[fieldConfig.name] && fieldsProps[fieldConfig.name].onChange) {
        fieldsProps[fieldConfig.name].onChange({ name, value })
      }
      if (fieldConfig && fieldConfig.onChange) {
        fieldConfig.onChange({ name, value })
      }
      setFieldValue(name, fieldConfig.mask ? fieldConfig.mask(value) : value)
    },
    [fieldsProps, fieldConfig, setFieldValue]
  )

  const onBlur = useCallback(({ name }) => handleBlur(name), [handleBlur])

  if (!fieldConfig) {
    return null
  }

  return renderer(
    {
      ...fieldConfig.props,
      ...(fieldsProps && fieldsProps[fieldConfig.name]),
      className: cx(fieldsProps.className, fieldConfig.props?.className, className),
      name: fieldConfig.name,
      required: fieldConfig.validation && fieldConfig.validation.indexOf('required') !== -1,
      value: values[fieldConfig.name],
      onChange,
      onBlur,
      error:
        (touched[fieldConfig.name] || isSubmitting || submitCount > 0) && errors[fieldConfig.name]
          ? errors[fieldConfig.name]
          : '',
    },
    fieldConfig.component
  )
})

export default Field
