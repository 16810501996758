import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import { mergeStyles } from '../../utils/StyleUtils'
import withMemo from '../../decorators/withMemo'
import MarkdownText from '../MarkdownText'

import styles from './styles'



const useStyles = createUseStyles(styles)

const PageTitle = (props) => {
  const { classes: classesProp, className, Tag, text } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  return (
    <MarkdownText
      className={cx(classes.container, className)}
      tag={Tag}
      inline
      text={text}
    />
  )
}

PageTitle.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  Tag: PropTypes.string,
  text: PropTypes.string.isRequired,
}

PageTitle.defaultProps = {
  className: null,
  classes: {},
  Tag: 'h1',
}

export default withMemo()(PageTitle)
