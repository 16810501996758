import { colors, textStyles } from '../../../../theme'


export default {
  container: {},
  inputContainer: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    padding: '0',
    border: [[1, 'solid', colors.pureBlack]],
    borderColor: ({ focused, error }) => (focused && !error && colors.pureBlack) || (error && colors.error) || colors.pureBlack,
    backgroundColor: ({ disabled, error }) => (disabled && colors.lightGrey) || (error && 'rgba(220, 11, 11, 0.05)') || colors.pureWhite,
  },
  label: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    overflow: 'hidden',
    ...textStyles.tiny,
    color: ({ focused, error }) => (focused && !error && colors.midGrey) || (error && colors.error) || colors.midGrey,
    '&[for]': {
      cursor: 'auto',
    },
    '& > $formLabel': {
      cursor: 'pointer',
    },
  },
  formLabel: {},
  formPlaceholder: {},
  input: {
    flex: 1,
    border: 0,
    padding: '1.1rem 1rem 0.9rem',
    ...textStyles.label,
    lineHeight: 1.2,
    color: ({ disabled }) => (disabled && colors.midGrey) || colors.pureBlack,
    background: 'transparent',
    opacity: 1, /* required on iOS */
    '&:disabled': {
      color: colors.midGrey,
      cursor: 'not-allowed',
    },
  },
  ornament: {
    padding: [['0', '0.5rem']],
  },
  startOrnament: {
  },
  endOrnament: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
  },
  errorText: {
    ...textStyles.tiny,
    fontSize: '1rem',
  },
  helpText: {},
}
