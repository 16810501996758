import Validator from 'validatorjs'
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile'

import { isValidId } from '../utils/YoutubeUtils'

export const validateAll = (values, rules, messages) => {
  const validation = new Validator(values, rules, messages)

  return new Promise((resolve, reject) => {
    validation.passes(() => {
      resolve()
    })

    validation.fails(() => {
      resolve(
        Object.keys(validation.errors.errors).reduce(
          (arr, key) => ({
            ...arr,
            [key]: validation.errors.errors[key][0],
          }),
          {}
        )
      )
    })
  })
}

Validator.register(
  'checked',
  (value, requirement = '', attribute) => !!value,
  'The :attribute field is required.'
)

Validator.register(
  'phone',
  (value, requirement = '', attribute) => {
    // requirement parameter defaults to null
    const [lang, force] = requirement.split(',')
    const phoneNumber = parsePhoneNumberFromString(value, lang || null)

    if (phoneNumber) {
      return (
        phoneNumber.isValid() &&
        phoneNumber.getType() === 'MOBILE' &&
        (!lang || !force || lang === phoneNumber.country)
      )
    }
    return false
  },
  'The :attribute phone number is not valid.'
)

Validator.register(
  'youtube',
  (value, requirement = '', attribute) => !!isValidId(value),
  'The :attribute youtube id is not valid.'
)

Validator.register(
  'richText',
  (value, requirement = '', attribute) => value.trim().length > 1,
  'The :attribute field is not valid.'
)

Validator.register(
  'zipCode',
  (value, requirement = '', attribute) => !!value.match(/^[0-9]{5}$/gi),
  'The :attribute field is not a valid zip code.'
)

Validator.register(
  'siret',
  (value, requirement = '', attribute) => !!value.match(/^[0-9]{14}$/gi),
  'The :attribute field is not a valid siret number.'
)

Validator.register(
  'code',
  (value, length = 4, attribute) =>
    !!value.match(new RegExp(`^[0-9]{${length}}$`, 'gi')),
  'The :attribute field is not a valid code'
)

Validator.register(
  'password',
  (value, requirement = '', attribute) =>
    !!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)[^ ]{8,}$/),
  'The :attribute field is not a valid password'
)

Validator.register(
  'fortune',
  (value, requirement, attribute) => {
    const count = value.reduce((r, v) => r + (parseInt(v, 10) || 0), 0)

    return count === 100
  },
  'Sum must be equal to 100'
)

Validator.register(
  'address',
  (value, requirement = '', attribute) =>
    value?.address && value?.position?.lat > 0,
  'Please select a valid address'
)

Validator.register(
  'fullAddress',
  (value, requirement = '', attribute) =>
    value?.address && value?.position?.lat > 0 && !!value?.components?.route,
  'Please select a valid address'
)
