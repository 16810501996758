import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import cx from 'classnames'

import { mergeStyles } from '../../utils/StyleUtils'
import withMemo from '../../decorators/withMemo'
import Field from '../form/Generator/Field'
import MarkdownText from '../MarkdownText'

import styles from './styles'


const useStyles = createUseStyles(styles)

const FormGenerator = (props) => {
  const { classes: classesProp, className, sections, formikProps, fieldsProps } = props
  const classesComp = useStyles(props)
  const classes = useMemo(() => mergeStyles(classesComp, classesProp), [classesProp, classesComp])

  // renderers
  const renderFields = (f) => f.map((field, i) => (
    <Field
      key={i}
      className={cx(className, classes.field, classes[`field_${field.name}`])}
      fieldConfig={{
        ...field,
        props: {
          ...field.props,
          autoComplete: 'off',
          autoCorrect: 'off',
          'aria-autocomplete': 'off',
          inputProps: {
            autoComplete: 'off',
            autoCorrect: 'off',
            'aria-autocomplete': 'off',
          },
          classes: {
            formLabel: classes.fieldFormLabel,
          },
        },
      }}
      fieldsProps={fieldsProps}
      formikProps={formikProps}
    />
  ))

  const renderSection = (section, index) => {
    if (section.type === 'text') {
      return (
        <MarkdownText
          key={`text-${index}`}
          text={section.content}
          {...section.markdownProps}
        />
      )
    }
    return renderFields(section.content)
  }

  return (
    <>
      {sections.map((section, index) => (
        renderSection(section, index)
      ))}
    </>
  )
}

FormGenerator.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  sections: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  formikProps: PropTypes.object,
  fieldsProps: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      help: PropTypes.string,
      placeholder: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string, value: PropTypes.string })
      ),
    })
  ),
}

FormGenerator.defaultProps = {
  className: null,
  classes: {},
  sections: [],
  formikProps: null,
  fieldsProps: null,
}

export default withMemo()(FormGenerator)
