import { textStyles } from '../../theme'


export default {
  field: {
  },
  text: {
    ...textStyles.text,
  },
}
